import * as yup from "yup";
import { useFormik } from "formik";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import { adminPostVideoCategory } from "../../lib/http";
import { VideoCategory } from "../../lib/definitions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

interface NewVideoCategoryDialogInterface {
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  name: string;
  admin: boolean;
  [key: string]: any;
}

const validationSchema = yup.object({
  name: yup.string().required("New category name is required."),
});

export function NewVideoCategoryDialog({
  open,
  onClose,
}: NewVideoCategoryDialogInterface) {
  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      admin: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const newCategory: VideoCategory = {
          name: values.name,
          admin: values.admin,
        };
        await adminPostVideoCategory(newCategory);
        onFormClose();
      } catch (err) {
        formik.setFieldError("name", (err as Error).message);
        formik.setSubmitting(false);
      }
    },
  });

  const onFormClose = () => {
    formik.resetForm();
    onClose();
  };

  const onFormSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>New Video Category</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a new video category.</DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            required
            fullWidth
            id="name"
            name="name"
            variant="standard"
            label="Category Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.admin}
                name="admin"
                defaultChecked={formik.values.admin}
                onChange={formik.handleChange}
              />
            }
            label="Admin Only"
          />
          <FormHelperText>
            If checked, will hide the category from non-admins. This does NOT
            control the visbility of videos in groups.
          </FormHelperText>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={onFormClose}>
          Cancel
        </Button>
        <Button disabled={formik.isSubmitting} onClick={onFormSubmit}>
          Save Category
        </Button>
      </DialogActions>
    </Dialog>
  );
}
