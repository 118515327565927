export type UserStatus =
  | "New"
  | "Approval"
  | "Blocked"
  | "OK"
  | "Error"
  | "Unknown";

export type User = {
  id?: string;
  user_id?: string;
  org_id?: string;
  role_id?: string;
  auth_id?: string;
  email: string;
  first_name: string;
  last_name: string;
  approved: boolean;
  blocked: boolean;
  password?: string;
  confirmPassword?: string;
  groups?: any;
  created_at?: Date | string;
  updated_at?: Date | string;
  [key: string]: any;
};

export type Role = {
  id: string;
  name: string;
  slug: string;
  created_at?: Date | string;
  updated_at?: Date | string;
};

export type Group = {
  id?: string;
  name: string;
  created_at?: Date | string;
  updated_at?: Date | string;
};

export type SingleUser = {
  user: User;
};

export type SingleUserAnalytics = {
  videos: VideoView[];
};

export type VideoView = {
  id: string;
  video: string;
  started: boolean;
  finished: boolean;
  percent: number;
  viewed_at: Date | string;
};

export type SingleGroup = {
  group: Group;
  users: any;
  videos: Video[];
};

export type Invitation = {
  id?: string;
  email: string;
  created_at?: Date | string;
};

export type AdminGroupResponse = {
  groups: Group[];
  total: number;
};

export type AdminUserResponse = {
  users: User[];
  total: number;
};

export type Video = {
  id?: string;
  title: string;
  thumbnail_url: string;
  source: string;
  url: string;
  status: string;
  videos?: number;
  users?: number;
  views?: number;
  groups?: any;
  files?: any;
  viewed_by?: any;
  published_at?: Date;
  expires_at?: Date;
  categories?: any;
  created_at?: Date | string;
};

export type AdminVideoResponse = {
  videos: Video[];
  total: number;
};

export type UserMessageResponse = {
  messages: Message[];
  read: string[];
  unreadCount: number;
};

export type Message = {
  id?: string;
  title: string;
  message: string;
  to_user?: any;
  to_groups?: any;
  send_at?: Date | string;
  created_at?: Date | string;
};

export type AdminMessageResponse = {
  messages: Message[];
  total: number;
};

export type UserVideoResponse = {
  videos: Video[];
  total: number;
  categories: VideoCategory[];
  favorites: Video[];
};

export type SingleVideo = {
  video: Video;
  categories: VideoCategory[];
  groups: Group[];
};

export type UserSingleVideo = {
  video: Video;
  files: OrgFile[];
  favorite: boolean;
};

export type VideoCategory = {
  id?: string;
  name: string;
  admin: boolean;
  count?: number;
  created_at?: Date | string;
  updated_at?: Date | string;
};

export type SearchResults = {
  videos?: Video[];
  groups?: Group[];
  files?: OrgFile[];
  categories?: VideoCategory[];
};

export type OrgFile = {
  id?: string;
  file_name: string;
  file_type: string;
  size?: number;
  videos?: any;
  groups?: any;
  created_at?: Date | string;
};

export type AdminFileResponse = {
  files: OrgFile[];
  total: number;
};

export type Entity = {
  id: string;
  name: string;
  thumbnail?: string;
  type: "File" | "Video" | "Group" | "Category" | "User";
};

export function getVideoID(video: Video): string | null {
  if (video.source === "youtube") {
    if (video.url.startsWith("https://www.youtube.com/watch?v=")) {
      const stripped = video.url.replace(
        "https://www.youtube.com/watch?v=",
        "",
      );
      const stripedParams = stripped.split("&");
      return stripedParams[0];
    }

    if (video.url.startsWith("https://www.youtube.com/embed/")) {
      const stripped = video.url.replace("https://www.youtube.com/embed/", "");
      const strippedParams = stripped.split("?");
      return strippedParams[0];
    }

    if (video.url.startsWith("https://youtu.be/")) {
      const stripped = video.url.replace("https://youtu.be/", "");
      return stripped;
    }
  }

  if (video.source === "vimeo") {
    if (video.url.startsWith("https://vimeo.com/")) {
      const stripped = video.url.replace("https://vimeo.com/", "");
      const strippedParams = stripped.split("?");
      return strippedParams[0];
    }
  }
  return null;
}
