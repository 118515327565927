import { useEffect, useState } from "react";
import filesize from "filesize";
import { format } from "date-fns";

import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import FolderIcon from "@mui/icons-material/Folder";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";

import { VideoThumbnail } from "../components/VideoThumbnail";

import { useUserVideos } from "../hooks/useUserVideos";
import { useUserFiles } from "../hooks/useUserFiles";

import { VideoCategory } from "../lib/definitions";

import styles from "./UserVideoFiles.module.css";
import { downloadFile } from "../lib/http";

const sortOrders = [
  {
    value: "recent",
    label: "Most Recent",
  },
  {
    value: "name",
    label: "Alphabetized",
  },
];

export function UserVideoFiles() {
  const [currentTab, setCurrentTab] = useState<string>("videos");
  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>My Videos &amp; Files</h1>
          <div className={styles.videosFilesTab}>
            <ul>
              <li
                className={`${currentTab === "videos" ? "active" : ""}`}
                onClick={() => setCurrentTab("videos")}
              >
                My Videos
              </li>
              <li
                className={`${currentTab === "files" ? "active" : ""}`}
                onClick={() => setCurrentTab("files")}
              >
                My Files
              </li>
            </ul>
          </div>
        </div>
      </header>

      <div className="page_content">
        <div className="inner">
          {currentTab === "videos" && <VideoListing />}
          {currentTab === "files" && <FileListing />}
        </div>
      </div>
    </main>
  );
}

function FileListing() {
  const { isLoading, data } = useUserFiles();

  return (
    <div className={styles.fileListingContainer}>
      {!isLoading && data && (
        <ul>
          {data.map((f) => {
            return (
              <li
                key={f.id}
                className={styles.file}
                onClick={() => {
                  downloadFile(f.id!);
                }}
              >
                <span className="name">{f.file_name}</span>
                <br />
                <span className="type">{filesize(f.size!)}</span>
                <span className="date">
                  Added on{" "}
                  {format(f.created_at as Date, "MM/dd/yyyy - hh:mmaa")}
                </span>
              </li>
            );
          })}
          {data.length === 0 && (
            <div className={styles.noVideos}>
              There are no files currently available
            </div>
          )}
        </ul>
      )}
    </div>
  );
}

function VideoListing() {
  const [categories, setCategories] = useState<VideoCategory[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentCategory, setCurrentCategory] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("name");

  const { isLoading, data, refetch } = useUserVideos(
    currentPage - 1,
    sortOrder,
    currentCategory,
  );

  useEffect(() => {
    let categories: VideoCategory[] = [
      {
        id: "",
        name: "All Videos",
        admin: false,
      },
    ];

    if (data) {
      categories.push({
        id: "favorites",
        name: "Favorites",
        count: data.favorites.length,
        admin: false,
      });
      data.categories.forEach((cat) => {
        categories.push(cat);
      });

      setCategories(categories);
    }
  }, [data]);

  return (
    <div className={styles.videoListingContainer}>
      <div className={styles.filters}>
        <TextField
          id="video_sort_order"
          select
          style={{ width: 250 }}
          label="Sort By"
          value={sortOrder}
          onChange={(event) => {
            setSortOrder(event.target.value);
            setCurrentPage(1);
          }}
        >
          {sortOrders.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={styles.videoContainer}>
        <div className={styles.categoryListing}>
          <List>
            {categories.map((cat) => {
              return (
                <ListItem disablePadding key={cat.id!}>
                  <ListItemButton
                    selected={currentCategory === cat.id!}
                    onClick={() => {
                      setCurrentCategory(cat.id!);
                      setCurrentPage(1);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={cat.name}
                      secondary={cat.count ? `${cat.count} videos` : ""}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </div>
        <div className={styles.videoListing}>
          <div className={styles.videos}>
            {!isLoading && data && (
              <>
                {data.videos.map((vid) => {
                  return <VideoThumbnail video={vid} key={vid.id} />;
                })}
                {data.videos.length === 0 && (
                  <div className={styles.noVideos}>
                    There are no videos currently available
                  </div>
                )}
              </>
            )}
            {isLoading && (
              <>
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
                <Skeleton variant="rectangular" width={258} height={160} />
              </>
            )}
          </div>
          <Pagination
            size="large"
            count={data ? Math.ceil(data.total / 12) : 0}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={(event, page) => {
              setCurrentPage(page);
              refetch();
            }}
          />
        </div>
      </div>
    </div>
  );
}
